<template>
  <v-form
    ref="formRegister"
    class="py-4"
    autocomplete="off"
    aria-autocomplete="off"
    @submit.prevent="isEdited ? updateProfile() : signUp()"
    disabled
  >
    <v-container>
      <v-row>
        <!-- <v-col v-if="!isEdited" cols="12" lg="12" sm="12" class="pb-0">
          <div
            class="d-flex flex-column justify-content-center align-items-center"
          >
            <i class="fas fa-lock c-tertiary login-icon"></i>
            <div class="title-1 mt-3">
              <h5 class="login-title readable">{{ labels.title[lang] }}</h5>
            </div>
          </div>
        </v-col> -->

        <v-col cols="12" lg="12" sm="12" class="pb-0">
          <v-alert v-if="hasResponseError" border="left" dense type="error">
            <strong>{{ labels.errorMessages.response[lang] }}</strong>
          </v-alert>
        </v-col>

        <!-- Register with google -->
        <!-- <v-col v-if="!userProp" cols="12" lg="12" sm="12">
          <p class="text-center text-medium">{{ labels.accessText[lang] }}</p>
          <div class="d-flex justify-content-center">
            <div id="googleRegister"></div>
          </div>
        </v-col> -->

        <v-col cols="12" lg="12" sm="12" class="pb-1 pt-1">
          <v-text-field
            :label="labels.vNombres[lang]"
            :rounded="rounded"
            :color="color"
            class="readable"
            :dark="dark"
            :background-color="bgColor"
            :rules="required"
            :disabled="disabled"
            :hide-details="details"
            :outlined="outlined"
            :filled="filled"
            :dense="dense"
            :autocomplete="autocomplete"
            v-model="payload.vNombres"
            :solo="solo"
            :class="{ [roundedFieldclass]: roundedFieldclass }"
          >
          </v-text-field>
        </v-col>
        <v-col cols="12" lg="12" sm="12" class="pb-1 pt-0">
          <v-text-field
            :label="labels.vApellidos[lang]"
            :rounded="rounded"
            :color="color"
            class="readable"
            :dark="dark"
            :background-color="bgColor"
            :rules="required"
            :disabled="disabled"
            :hide-details="details"
            :outlined="outlined"
            :filled="filled"
            :dense="dense"
            :autocomplete="autocomplete"
            v-model="payload.vApellidos"
            :solo="solo"
            :class="{ [roundedFieldclass]: roundedFieldclass }"
          >
          </v-text-field>
        </v-col>
        <v-col cols="12" lg="12" sm="12" class="pb-1 pt-0">
          <v-text-field
            :label="labels.vUsuario[lang]"
            :rounded="rounded"
            :color="color"
            class="readable"
            :dark="dark"
            :background-color="bgColor"
            :rules="[...required, ...rules.email]"
            :disabled="disabled || isEdited || registerWithSocial"
            :hide-details="details"
            :outlined="outlined"
            :filled="filled"
            :dense="dense"
            :autocomplete="autocomplete"
            v-model="payload.vUsuario"
            :solo="solo"
            :class="{ [roundedFieldclass]: roundedFieldclass }"
          >
          </v-text-field>
        </v-col>


        <!-- <v-col
          v-if="(!sessionUser || !sessionUser.SOCIAL_ID) && !registerWithSocial"
          cols="12"
          lg="12"
          sm="12"
          class="pb-1 pt-0"
        >
          <v-text-field
            :label="labels.vClave[lang]"
            :rounded="rounded"
            :color="color"
            class="readable"
            :dark="dark"
            :background-color="bgColor"
            :rules="passwordRule"
            :disabled="disabled"
            :hide-details="details"
            :outlined="outlined"
            :filled="filled"
            :dense="dense"
            :autocomplete="autocomplete"
            type="password"
            :append-icon="passwordMatch ? 'check_circle' : ''"
            v-model="payload.vClave"
            :solo="solo"
            :class="{ [roundedFieldclass]: roundedFieldclass }"
          >
          </v-text-field>
        </v-col>
        <v-col
          v-if="(!sessionUser || !sessionUser.SOCIAL_ID) && !registerWithSocial"
          cols="12"
          lg="12"
          sm="12"
          class="pb-1 pt-0"
        >
          <v-text-field
            :label="labels.vClave2[lang]"
            :rounded="rounded"
            :color="color"
            class="readable"
            :dark="dark"
            :background-color="bgColor"
            :rules="required"
            :disabled="disabled"
            :hide-details="details"
            :outlined="outlined"
            :filled="filled"
            :dense="dense"
            :autocomplete="autocomplete"
            type="password"
            :append-icon="passwordMatch ? 'check_circle' : 'cancel'"
            v-model="payload.vClave2"
            :solo="solo"
            :class="{ [roundedFieldclass]: roundedFieldclass }"
          >
          </v-text-field>
          <v-alert
            v-if="!passwordMatch"
            border="left"
            class="readable"
            outlined
            dense
            type="error"
          >
            <strong>{{ labels.errorMessages.passwords[lang] }}</strong>
          </v-alert>
          <v-alert v-else border="left" dense outlined type="success">
            <strong>{{ labels.successMessages.passwords[lang] }}</strong>
          </v-alert>
        </v-col>
        <v-col cols="12" lg="12" sm="12" class="pb-1 pt-0">
          <v-radio-group v-model="payload.iTipoDocumento" row>
            <v-radio
              class="readable"
              :label="labels.iTipoDocumento.firstOpt[lang]"
              :value="1"
            ></v-radio>
            <v-radio
              class="readable"
              :label="labels.iTipoDocumento.secondOpt[lang]"
              :value="2"
            ></v-radio>
          </v-radio-group>
        </v-col>
        <v-col cols="12" lg="12" sm="12" class="pb-1 pt-0">
          <v-text-field
            :label="labels.vNumeroDocumento[lang]"
            v-mask="documentMask"
            :rounded="rounded"
            :color="color"
            class="readable"
            :dark="dark"
            :background-color="bgColor"
            :rules="[...required, ...rules.document]"
            :disabled="disabled"
            :hide-details="details"
            :outlined="outlined"
            :filled="filled"
            :dense="dense"
            :autocomplete="autocomplete"
            v-model="payload.vNumeroDocumento"
            :solo="solo"
            :class="{ [roundedFieldclass]: roundedFieldclass }"
          >
          </v-text-field>
        </v-col>
        <v-col cols="12" lg="12" sm="12" class="pb-1 pt-0">
          <v-btn
            dark
            :loading="loading"
            :disabled="loading"
            depressed
            type="submit"
            class="d-block w-100 readable"
            color="tertiary"
            :class="{ [roundedFieldclass]: roundedFieldclass }"
          >
            {{
              isEdited ? labels.buttonUpdate[lang] : labels.buttonSubmit[lang]
            }}
          </v-btn>
          <br />
          <v-btn
            v-if="!userProp"
            :disabled="loading"
            depressed
            rounded
            text
            type="button"
            class="d-block w-100 readable elevation-0"
            color="tertiary"
            :class="{ [roundedFieldclass]: roundedFieldclass }"
            @click="restoreForm"
          >
            {{ labels.buttonReset[lang] }}
          </v-btn>
        </v-col> -->
        <v-col cols="12" lg="12" sm="12" class="pb-1 pt-0"> </v-col>
        <v-col cols="12" lg="12" sm="12" class="pb-1 pt-0" v-if="!isEdited">
          <div class="d-flex justify-content-between py-2 mt-2">
            <!-- <button
              type="button"
              class="text-link c-primary text-medium readable"
              
              @click="$emit('goingToLogin')"
            >
              {{ labels.login[lang] }}
            </button> -->
            <!-- <router-link to="/recuperar-cuenta" class="text-link text-medium">{{
              labels.recover[lang]
            }}</router-link> -->
          </div>
        </v-col>
      </v-row>
    </v-container>
  </v-form>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import { emailValidator } from "@/libs/validators.js";
import baseConfig from "./baseConfig";
import { initGooglesdk } from "@/plugins/gAuth";

export default {
  props: {
    userProp: Object,
  },
  data() {
    return {
      ...baseConfig,
      required: [this.validateRequired],
      passwordRule: [this.validateRequired, this.validatePasswordLength],
      labels: {
        accessText: {
          en: "You can register with",
          es: "Puedes registrarte con",
        },
        title: {
          en: "Sign up",
          es: "Registrate",
        },
        vUsuario: {
          en: "Username",
          es: "Usuario",
        },
        vClave: {
          en: "Password",
          es: "Contraseña",
        },
        vClave2: {
          en: "Confirm password",
          es: "Confirmar contraseña",
        },
        vNombres: {
          en: "First and middle name",
          es: "Nombres",
        },
        vApellidos: {
          en: "Last name",
          es: "Apellidos",
        },
        iTipoDocumento: {
          en: "Document type",
          es: "Tipo de documento",
          firstOpt: {
            en: "DNI",
            es: "DNI",
          },
          secondOpt: {
            en: "Pasaport / IC",
            es: "Pasaporte / C.E",
          },
        },
        vNumeroDocumento: {
          en: "Document number",
          es: "Número de documento",
        },
        buttonSubmit: {
          en: "Sign up",
          es: "Registrarme",
        },
        buttonUpdate: {
          es: "Guardar",
          en: "Save",
        },
        buttonReset: {
          en: "Restore Form",
          es: "Reiniciar Formulario",
        },
        login: {
          en: "Log in",
          es: "Inicia sesión",
        },
        recover: {
          en: "Recover account",
          es: "Recuperar cuenta",
        },
        errorMessages: {
          passwords: {
            en: "Passwords do not match",
            es: "Las contraseñas no coinciden",
          },
          email: {
            en: "Wrong email",
            es: "Correo incorrecto",
          },
          dni: {
            length: 8,
            en: "Incorrect DNI",
            es: "DNI inválido",
          },
          passport: {
            length: 8,
            en: "Incorrect Pasaport / IC",
            es: "Pasaporte / C.E incorrecto",
          },
          response: {
            es:
              "Ocurrió un error registrando su cuenta de usuario, intentelo nuevamente",
            en:
              "An error occurred registering your user account, please try again",
          },
        },
        successMessages: {
          passwords: {
            en: "Passwords match",
            es: "Las contraseñas coinciden",
          },
        },
        requiredLabel: {
          en: "Required field",
          es: "Campo requerido",
        },
        passwordLength: {
          en: "Password must contain at least 6 characters",
          es: "La contraseña debe contener al menos 6 caracteres",
        },
      },
      passwordMatch: false,
      hasResponseError: false,
      documentMask: "########",
      rules: {
        min3: [],
        email: [this.validateEmail],
        document: [this.validateDocumentLength],
      },
      loading: false,
      payload: this.userProp
        ? { ...this.userProp }
        : {
            vUsuario: "",
            vClave: "",
            vClave2: "",
            vNombres: "",
            vApellidos: "",
            iTipoDocumento: 1,
            vNumeroDocumento: "",
            // no requeridos en este registro
            foto: "",
            idUsuario: 0,
            iOrigen: 1,
            result: "",
          },
      registerWithSocial: false,
    };
  },
  watch: {
    "payload.vClave": function(val) {
      this.passwordMatch = val === this.payload.vClave2 ? true : false;
    },
    "payload.vClave2": function(val) {
      this.passwordMatch = val === this.payload.vClave ? true : false;
    },
    "payload.iTipoDocumento": function(val) {
      this.documentMask = val == 1 ? "########" : "############";
      this.payload.vNumeroDocumento = "";
    },
    "payload.vNombres": function(val) {
      this.payload.vNombres = String(val).toUpperCase();
    },
    "payload.vApellidos": function(val) {
      this.payload.vApellidos = String(val).toUpperCase();
    },
    "payload.vUsuario": function(val) {
      this.payload.vUsuario = String(val)
        .replace(/ /g, "")
        .trim()
        .toLowerCase();
    },
  },
  computed: {
    ...mapGetters({
      lang: "getLanguage",
      sessionUser: "getSessionUser",
    }),
    isEdited() {
      return this.userProp ? true : false;
    },
  },
  methods: {
    ...mapActions({
      register: "registerUser",
      updateProfileUser: "updateUser",
    }),
    validateRequired(v) {
      if (!v) return this.labels.requiredLabel[this.lang];
      return true;
    },
    validatePasswordLength(v) {
      if (v.length < 6) return this.labels.passwordLength[this.lang];
      return true;
    },
    validateEmail(v) {
      return emailValidator(v) || this.labels.errorMessages.email[this.lang];
    },
    validateDocumentLength(v) {
      if (this.payload.iTipoDocumento == 1) {
        return (
          v.length >= this.labels.errorMessages.dni.length ||
          this.labels.errorMessages.dni[this.lang]
        );
      } else {
        return (
          v.length >= this.labels.errorMessages.passport.length ||
          this.labels.errorMessages.passport[this.lang]
        );
      }
    },
    validateForm() {
      return this.$refs.formRegister.validate();
    },
    async signUp() {
      if (!this.validateForm()) return;
      if (!this.passwordMatch) return;
      this.loading = true;
      const res = await this.register(this.payload);
      this.loading = false;
      if (res === true) {
        return (this.hasResponseError = false);
      }

      if (typeof res == "object") {
        console.log(res);
      }
      /* if (!res) {
        // this.hasResponseError = true;
        // this.$emit("uploadToError");
        return;
      } */
    },
    async updateProfile() {
      const isSocialLogin = this.sessionUser
        ? this.sessionUser.SOCIAL_ID
        : null;
      if (!this.validateForm()) return;
      if (!this.passwordMatch && !isSocialLogin) return;
      this.loading = true;
      const userToUpdate = {
        ...this.payload,
        vClave: isSocialLogin
          ? this.sessionUser.SOCIAL_ID
          : this.payload.vClave,
        iOrigen: 1,
        foto: this.sessionUser.URLFOTO || "",
        result: this.sessionUser.METADATA || {}
      };
      const res = await this.updateProfileUser(userToUpdate);
      this.loading = false;
      if (!res) {
        this.hasResponseError = true;
        return;
      }
      this.hasResponseError = false;
    },
    async loginWithGoogle(payload) {
      this.payload = {
        vUsuario: payload.vUsuario,
        vClave: payload.vClave,
        vClave2: payload.vClave,
        vNombres: payload.vNombres,
        vApellidos: payload.vApellidos,
        iTipoDocumento: 1,
        vNumeroDocumento: "",
        // no requeridos en este registro
        foto: payload.foto,
        idUsuario: 0,
        iOrigen: 3,
        result: payload.result,
      };
      this.registerWithSocial = true;
    },
    restoreForm() {
      this.registerWithSocial = false;
      this.payload = {
        vUsuario: "",
        vClave: "",
        vClave2: "",
        vNombres: "",
        vApellidos: "",
        iTipoDocumento: 1,
        vNumeroDocumento: "",
        // no requeridos en este registro
        foto: "",
        idUsuario: 0,
        iOrigen: 1,
        result: "",
      };
    }
  },
  mounted() {
    // initGooglesdk(this, "googleRegister");
  },
};
</script>

<style lang="scss">
.text-link {
  color: #444 !important;
  text-decoration: none;
}
.text-link:hover {
  color: var(--color-blue) !important;
}
.login-icon {
  font-size: 25px;
}
.title-1 {
  width: 100%;
  display: flex;
  justify-content: center;
  position: relative;
  &::before {
    content: "";
    position: absolute;
    top: 50%;
    left: 0;
    width: 100%;
    height: 3px;
    background: var(--color-blue);
  }
}
.login-title {
  font-size: 16px;
  background: #fff;
  position: relative;
  padding: 3px 8px;
}
</style>
